export const FETCH_ALL_ARTICLES = "fetchArticles";
export const FETCH_ALL_ARTICLES_BY_LOC = "fetchArticlesByLoc";
export const FETCH_ALL_ARTICLES_BY_IDS = "fetchArticlesByIds";
export const FETCH_MORE_ARTICLES_BY_IDS_SEQUENTIAL =
  "fetchMoreArticlesByIdsSequential";
export const FETCH_MORE_SNAPS_BY_IDS_SEQUENTIAL =
  "fetchMoreSnapsByIdsSequential";
export const FETCH_MORE_ARTICLES_BY_IDS = "fetchArticlesByIds";
export const FETCH_ALL_ARTICLE_IDS_BY_LOC = "fetchArticleIdsByLoc";
export const FETCH_ARTICLE = "fetchArticle";
export const TOGGLE_BOOKMARK = "toggleBookmark";
export const POST_ARTICLE = "postArticle";
export const POST_FB_LOGIN = "postFbLogin";
export const REFRESH_TOKEN = "refreshToken";
export const POST_GOOGLE_LOGIN = "postGoogleLogin";
export const POST_GOOGLE_NEW_LOGIN = "postGoogleNewLogin";
export const REGISTER_USER = "registerUser";
export const LOGIN_USER = "loginUser";
export const LOGOUT_USER = "logoutUser";
export const FETCH_LOGGED_IN_USER_PROFILE = "fetchLoggedInUserProfile";
export const FETCH_CURRENT_USER_PROFILE = "fetchCurrentUserProfile";
export const FOLLOW_UNFOLLOW_USER = "followUnfollowUser";
export const SAVE_SUBSCRIPTION_ID = "saveSubscriptionId";
export const DELETE_SUBSCRIPTION_ID = "deleteSubscriptionId";
export const CHECK_IF_USER_EXISTS = "checkIfUserExists";
export const UPDATE_USER_PROFILE = "updateUserProfile";
export const FETCH_BOOKMARKS = "fetchBookmarks";
export const FETCH_POSTS = "fetchPosts";
export const FETCH_DRAFTS = "fetchDrafts";
export const FETCH_TRENDING_LOCATIONS = "fetchTrendingLocations";
export const FETCH_CURRENT_LOCATION = "fetchCurrentLocation";
export const FETCH_CURRENT_IP_LOCATION = "fetchCurrentIpLocation";
export const FETCH_CATEGORIES = "fetchCategories";
