import ArticlesService from "@/services/articlesService";
import ProfileService from "@/services/profileService";

export const state = () => ({
  tags: [],
  articles: [],
  snaps: [],
  bookmarks: [],
  posts: [],
  drafts: [],
  articleIds: [],
  categories: [],
});

export const actions = {
  FETCH_ALL_ARTICLES({ commit }) {
    commit("SET_ARTICLES", []);
    return ArticlesService.getAll()
      .then(({ data }) => {
        console.log("all articles are ");
        commit(SET_ARTICLES, data.results);
        if (data.results.length === 0) {
          throw new Error("No Articles found.");
        }
      })
      .catch(error => {
        throw new Error(error);
      });
  },

  FETCH_ALL_ARTICLES_BY_LOC({ commit }, payload) {
    commit("SET_ARTICLES", []);
    return ArticlesService.getAllByLocation(payload)
      .then(({ data }) => {
        commit(SET_ARTICLES, data.results);
        if (data.results.length === 0) {
          throw new Error("No Articles found in this location.");
        }
      })
      .catch(error => {
        commit("SET_ARTICLES", []);
        throw new Error(error);
      });
  },

  FETCH_ALL_ARTICLES_BY_IDS({ commit }, payload) {
    commit("SET_ARTICLES", []);
    return ArticlesService.getArticlesByIds(payload)
      .then(({ data }) => {
        commit("SET_ARTICLES", data);
        if (data.length === 0) {
          throw new Error("No Articles found.");
        }
      })
      .catch(error => {
        throw new Error(error);
      });
  },
  async FETCH_MORE_ARTICLES_BY_IDS_SEQUENTIAL({ commit }, payload) {
    console.log("payload for request is ", payload);

    for (const element of payload) {
      let config = {
        params: {
          ids: element
        }
      };
      console.log("Getting articles for element ", element);
      let res = await this.$axios.get("/api/articles/", config).catch(error => {
        throw new Error(error);
      });

      console.log("response in getting articles for `element` is ", res.data);
      commit("ADD_ARTICLES", res.data);
    }
  },
  async FETCH_MORE_SNAPS_BY_IDS_SEQUENTIAL({ commit }, payload) {
    console.log("payload for request is ", payload);

    for (const element of payload) {
      let config = {
        params: {
          ids: element,
          is_snaps: true
        }
      };
      console.log("Getting snaps for element ", element);
      let res = await this.$axios.get("/api/articles/", config).catch(error => {
        throw new Error(error);
      });

      console.log("response in getting snaps for `element` is ", res.data);
      commit("ADD_SNAPS", res.data);
    }
  },

  FETCH_MORE_ARTICLES_BY_IDS({ commit }, payload) {
    payload.forEach(element => {
      ArticlesService.getArticlesByIds([element])
        .then(({ data }) => {
          commit("ADD_ARTICLES", data);
          if (data.length === 0) {
            throw new Error("No Articles found.");
          }
        })
        .catch(error => {
          throw new Error(error);
        });
    });
  },
  FETCH_ALL_ARTICLE_IDS_BY_LOC({ commit }, payload) {
    console.log("Inside FETCH_ALL_ARTICLE_IDS_BY_LOC");
    commit("SET_ARTICLES", []);
    return ArticlesService.getArticleIdsByLoc(payload)
      .then(({ data }) => {
        console.log("data in FETCH_ALL_ARTICLE_IDS_BY_LOC", data);
        commit("SET_ARTICLE_IDS", data);
        if (data.length === 0) {
          throw new Error("No Articles found.");
        }
      })
      .catch(error => {
        throw new Error(error);
      });
  },

  FETCH_POSTS({ commit }, authorId) {
    commit("SET_POSTS", []);
    return ArticlesService.getArticlesByAuthor(authorId)
      .then(({ data }) => {
        console.log("data from fetching posts is ", data);
        commit("SET_POSTS", data.results);
        if (data.results.length === 0) {
          throw new Error("No Posts found.");
        }
      })
      .catch(error => {
        throw new Error(error);
      });
  },

  FETCH_DRAFTS({ commit }, authorId) {
    commit("SET_DRAFTS", []);
    return ArticlesService.getDraftsByAuthor(authorId)
      .then(({ data }) => {
        console.log("data from fetching drafts is ", data);
        commit("SET_DRAFTS", data.results);
        if (data.results.length === 0) {
          throw new Error("No Drafts found.");
        }
      })
      .catch(error => {
        throw new Error(error);
      });
  },

  async FETCH_BOOKMARKS({ commit }, userParams) {
    commit("SET_BOOKMARKS", []);
    console.log("user arguments to fetch bookmarks are ", userParams);

    this.$axios.setToken(
      userParams.userInfo.userAccessToken,
      userParams.userInfo.tokenType
    );

    let res = await this.$axios.get("/api/profile/bookmarks").catch(error => {
      throw new Error(error);
    });
    console.log("response in fetching bookmarks is ", res.data);
    if (res.data && res.data.results.length > 0) {
      return ArticlesService.getArticlesByIds(res.data.results)
        .then(({ data }) => {
          commit("SET_BOOKMARKS", data);
          if (data.length === 0) {
            throw new Error("No Articles found.");
          }
        })
        .catch(error => {
          throw new Error(error);
        });
    }
  },

  async FETCH_CATEGORIES({ commit }) {
    commit("SET_CATEGORIES", []);
    console.log("fetch categories ");

    let res = await this.$axios.get("/api/categories/").catch(error => {
      throw new Error(error);
    });
    console.log("response in getting categories is ", res.data);
    if (res.data && res.data.length > 0) {
      commit("SET_CATEGORIES", res.data);
    }
  }
};

/* eslint no-param-reassign: ["error", { "props": false }] */
export const mutations = {
  SET_ARTICLES(currentState, articles) {
    currentState.articles = articles;
  },
  CLEAR_ARTICLES(currentState, articles) {
    currentState.articles = [];
  },
  CLEAR_SNAPS(currentState, articles) {
    currentState.snaps = [];
  },
  SET_ARTICLE_IDS(currentState, articleIds) {
    currentState.articleIds = articleIds;
  },
  SET_BOOKMARKS(currentState, bookmarks) {
    currentState.bookmarks = bookmarks;
  },
  SET_POSTS(currentState, posts) {
    currentState.posts = posts;
  },
  SET_DRAFTS(currentState, drafts) {
    currentState.drafts = drafts;
  },
  ADD_ARTICLES(currentState, articles) {
    currentState.articles = currentState.articles.concat(articles);
  },
  ADD_SNAPS(currentState, snaps) {
    currentState.snaps = currentState.snaps.concat(snaps);
  },
  SET_CATEGORIES(currentState, categories) {
    currentState.categories = categories;
  }
};

export default {
  state,
  actions,
  mutations
};
