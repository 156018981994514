import Vue from "vue";
import axios from "axios";
import jsonp from "jsonp";
import { Config } from "@/config.js";

export default {
  autocompleteLocation(input) {
    console.log("Inside auto complete function in location service");
    let params = {
      input: input,
      types: ["(cities)"],
      key: "AIzaSyDntGNt1hm0BriK0cwbdDkL9iIb4hVPKEo"
    };
    let url = "https://maps.googleapis.com/maps/api/place/autocomplete/";
    return jsonp(url, params, (error, data) => {
      if (error) {
        console.log(error);
      } else {
        console.log(data);
      }
    });
  },
  getCityFromLatLong(lat, lng) {
    let params = {
      latlng: lat.toString() + "," + lng.toString(),
      key: "AIzaSyDntGNt1hm0BriK0cwbdDkL9iIb4hVPKEo"
    };
    let url = "https://maps.googleapis.com/maps/api/geocode/json";
    return jsonp(url, params, (error, data) => {
      if (error) {
        console.log(error);
      } else {
        console.log(data);
      }
    });
  },
  getTrendingLocations() {
    return axios
      .get(Config.HOST + "/api/trendinglocations/")
      .catch(error => {
        throw new Error(error);
      });
  },

  getUserCompleteAddress: function() {
    console.log("getting user city and state from geolocation");
    return new Promise(function(resolve, reject) {
      if ("geolocation" in navigator) {
        // check if geolocation is supported/enabled on current browser
        navigator.geolocation.getCurrentPosition(
          function success(position) {
            // for when getting location is a success
            console.log(
              "latitude",
              position.coords.latitude,
              "longitude",
              position.coords.longitude
            );

            Vue.axios
              .post(
                "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
                  position.coords.latitude +
                  "," +
                  position.coords.longitude +
                  "&key=" +
                  "AIzaSyAe7WFQtEEnSMtal7_lgjIzJOEZx_IfEb4"
              )
              .then(resp => {
                let address_components =
                  resp.data.results[0].address_components;
                console.log(address_components);

                let location_data = {
                  latitude: resp.data.results[0].geometry.location.lat,
                  longitude: resp.data.results[0].geometry.location.lng,
                  address_components: resp.data.results[0].address_components
                };
                resolve(location_data);
              });
          },
          function error(error_message) {
            // for when getting location results in an error
            console.error(
              "An error has occured while retrieving location",
              error_message
            );
            reject(error_message);
          }
        );
      } else {
        // geolocation is not supported
        // get your location some other way
        console.log("geolocation is not enabled on this browser");
        reject("not supported in the browser");
      }
    });
  },
  getUserDescLocation: function() {
    console.log("getting user city and state from geolocation");

    return new Promise(function(resolve, reject) {
      var useIp = false;
      if ("geolocation" in navigator) {
        console.log("getting user locatin using browser");
        // check if geolocation is supported/enabled on current browser
        navigator.geolocation.getCurrentPosition(
          function success(position) {
            // for when getting location is a success
            console.log(
              "latitude",
              position.coords.latitude,
              "longitude",
              position.coords.longitude
            );

            Vue.axios
              .post(
                "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
                  position.coords.latitude +
                  "," +
                  position.coords.longitude +
                  "&key=" +
                  "AIzaSyAe7WFQtEEnSMtal7_lgjIzJOEZx_IfEb4"
              )
              .then(resp => {
                let address_components =
                  resp.data.results[0].address_components;
                console.log(address_components);

                var i;
                var city = "";
                var state = "";
                for (i = 0; i < address_components.length; i++) {
                  if (address_components[i].types.indexOf("locality") != -1) {
                    city = address_components[i].long_name;
                  } else if (
                    address_components[i].types.indexOf(
                      "administrative_area_level_1"
                    ) != -1
                  ) {
                    state = address_components[i].long_name;
                  } else {
                    continue;
                  }
                }

                // let userDescAddr = city + ", " + state;
                let userDescAddr = {
                  city: city,
                  state: state
                };
                console.log(userDescAddr);
                resolve(userDescAddr);
              });
          },
          function error(error_message) {
            useIp = true;
            // for when getting location results in an error
            console.error(
              "An error has occured while retrieving location",
              error_message
            );
            console.log("geolocation is not enabled on this browser...");
            Vue.axios.post("https://ipapi.co/json").then(resp => {
              console.log("Getting user location using ip");
              console.log(resp.data.latitude);
              console.log(resp.data.longitude);
              Vue.axios
                .post(
                  "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
                    resp.data.latitude +
                    "," +
                    resp.data.longitude +
                    "&key=" +
                    "AIzaSyAe7WFQtEEnSMtal7_lgjIzJOEZx_IfEb4"
                )
                .then(resp => {
                  let address_components =
                    resp.data.results[0].address_components;
                  console.log(address_components);

                  var i;
                  var city = "";
                  var state = "";
                  for (i = 0; i < address_components.length; i++) {
                    if (address_components[i].types.indexOf("locality") != -1) {
                      city = address_components[i].long_name;
                    } else if (
                      address_components[i].types.indexOf(
                        "administrative_area_level_1"
                      ) != -1
                    ) {
                      state = address_components[i].long_name;
                    } else {
                      continue;
                    }
                  }
                  let userDescAddr = {
                    city: city
                  };
                  console.log(userDescAddr);
                  resolve(userDescAddr);
                });
            });
            // reject(error_message);
          }
        );
      } else {
        // geolocation is not supported
        // get your location some other way
        console.log("geolocation is not enabled on this browser");
        Vue.axios.post("https://ipapi.co/json").then(resp => {
          // console.log("Getting user lat")
          console.log(resp.data);
          Vue.axios
            .post(
              "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
                resp.data.lat +
                "," +
                resp.data.lon +
                "&key=" +
                "AIzaSyAe7WFQtEEnSMtal7_lgjIzJOEZx_IfEb4"
            )
            .then(resp => {
              let address_components = resp.data.results[0].address_components;
              console.log(address_components);

              var i;
              var city = "";
              var state = "";
              for (i = 0; i < address_components.length; i++) {
                if (address_components[i].types.indexOf("locality") != -1) {
                  city = address_components[i].long_name;
                } else if (
                  address_components[i].types.indexOf(
                    "administrative_area_level_1"
                  ) != -1
                ) {
                  state = address_components[i].long_name;
                } else {
                  continue;
                }
              }
              let userDescAddr = {
                city: city
              };
              console.log(userDescAddr);
              resolve(userDescAddr);
            });
        });
      }
    });
  },
  parseLatLong: function(latLongDict) {
    return new Promise(function(resolve) {
      Vue.axios
        .post(
          "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
            latLongDict.lat +
            "," +
            latLongDict.long +
            "&key=" +
            "AIzaSyAe7WFQtEEnSMtal7_lgjIzJOEZx_IfEb4"
        )
        .then(resp => {
          let address_components = resp.data.results[0].address_components;
          console.log(address_components);

          var i;
          var city = "";
          var state = "";
          for (i = 0; i < address_components.length; i++) {
            if (address_components[i].types.indexOf("locality") != -1) {
              city = address_components[i].long_name;
            } else if (
              address_components[i].types.indexOf(
                "administrative_area_level_1"
              ) != -1
            ) {
              state = address_components[i].long_name;
            } else {
              continue;
            }
          }

          let userDescAddr = city + ", " + state;
          console.log(userDescAddr);
          resolve(userDescAddr);
        });
    });
  },
  parseAddressComponents: function(addressComponents) {
    var i;
    var city = "";
    var state = "";
    for (i = 0; i < addressComponents.length; i++) {
      if (addressComponents[i].types.indexOf("locality") != -1) {
        city = addressComponents[i].long_name;
      } else if (
        addressComponents[i].types.indexOf("administrative_area_level_1") != -1
      ) {
        state = addressComponents[i].long_name;
      } else {
        continue;
      }
    }

    let userDescAddr = city + ", " + state;
    console.log(userDescAddr);
    return userDescAddr;
  },
  getUserLatLong: function() {
    console.log("getting user lat and long from geolocation");
    var self = this;
    return new Promise(function(resolve, reject) {
      // if ("geolocation" in navigator) {
      //   // check if geolocation is supported/enabled on current browser
      //   navigator.geolocation.getCurrentPosition(
      //     function success(position) {
      //       var userLatLong = {
      //         latitude: position.coords.latitude,
      //         longitude: position.coords.longitude
      //       };
      //       Vue.axios
      //         .post(
      //           "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
      //             position.coords.latitude +
      //             "," +
      //             position.coords.longitude +
      //             "&key=" +
      //             "AIzaSyAe7WFQtEEnSMtal7_lgjIzJOEZx_IfEb4"
      //         )
      //         .then(
      //           resp => {
      //             let address_components =
      //               resp.data.results[0].address_components;
      //             console.log(address_components);

      //             var i;
      //             var city = "";
      //             var state = "";
      //             for (i = 0; i < address_components.length; i++) {
      //               if (address_components[i].types.indexOf("locality") != -1) {
      //                 userLatLong["line3"] = address_components[i].long_name;
      //               } else if (
      //                 address_components[i].types.indexOf(
      //                   "administrative_area_level_1"
      //                 ) != -1
      //               ) {
      //                 userLatLong["region_code"] =
      //                   address_components[i].long_name;
      //                 userLatLong["region"] = address_components[i].long_name;
      //               } else if (
      //                 address_components[i].types.indexOf("country") != -1
      //               ) {
      //                 userLatLong["country"] = address_components[i].long_name;
      //               } else if (
      //                 address_components[i].types.indexOf("postal_code") != -1
      //               ) {
      //                 userLatLong["postal"] = address_components[i].long_name;
      //               } else {
      //                 continue;
      //               }
      //             }
      //             console.log(userLatLong);
      //             resolve(userLatLong);
      //           },
      //           error => {
      //             reject(error);
      //           }
      //         );
      //       // for when getting location is a success
      //       // console.log(userLatLong);
      //       // resolve(userLatLong);
      //     },
      //     function error(error_message) {
      //       // for when getting location results in an error
      //       console.error(
      //         "An error has occured while retrieving location",
      //         error_message
      //       );
      //       self.getUserLatLongFromIpLookup().then(
      //         response => {
      //           resolve(response);
      //         },
      //         error => {
      //           reject(error);
      //         }
      //       );
      //     }
      //   );
      // } else {
      self.getUserLatLongFromIpLookup().then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
      // }
    });
  },
  getUserLatLongFromIpLookup: function() {
    console.log("getting user city and state using iplookup");
  }
};
