export default function({ app, $axios }) {
  $axios.onRequest(config => {
    console.log("Making request to " + config.url);
  });

  $axios.onError(error => {
    if (error.name !== "ExpiredAuthSessionError") {
      const code = parseInt(error.response && error.response.status);
      if (code === 400) {
        console.log("sfsdfs");
      }
    }
  });
}
