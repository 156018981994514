export const SET_ARTICLES = "setArticles";
export const ADD_ARTICLES = "addArticles";
export const SET_ARTICLE_IDS = "setArticleIds";
export const SET_USER = "setUser";
export const RESET_USER = "resetUser";
export const SET_FB_USER = "setFbUser";
export const SET_ARTICLE = "setArticle";
export const SET_USER_PROFILE = "setUserProfile";
export const SET_BOOKMARKS = "setBookmarks";
export const SET_POSTS = "setPosts";
export const SET_TRENDING_LOCATIONS = "setTrendingLocations";
export const CLEAR_ARTICLES = "clearArticles";
export const SET_GOOGLE_USER = "setGoogleUser";
export const SET_CURRENT_LOCATION = "setCurrentLocation";
export const RESET_USER_PROFILE = "RESET_USER_PROFILE";
export const SET_CATEGORIES = "setCategories";
