import UserService from "@/services/userService";
import ProfileService from "@/services/profileService";
import store from "@/store";
import {
  Config
} from "@/config.js";

export const state = {
  userAccessToken: "",
  refreshToken: "",
  tokenType: "",
  profile_pic: "",
  loginType: "", //FB, GOOGLE, STREET
  latitude: undefined,
  longitude: undefined,
  bookmarks: [],
  firstName: "",
  lastName: "",
  settings: {
    notifications: false
  }
};

export const actions = {
  POST_FB_LOGIN({
    commit
  }, payload) {
    return UserService.post(payload["accessToken"])
      .then(({
        data
      }) => {
        console.log(data);
        data.profile_pic =
          "http://graph.facebook.com/" +
          payload["userID"] +
          "/picture?type=normal";
        data.firstName = payload.firstName;
        data.lastName = payload.lastName;
        commit("SET_FB_USER", data, payload);
      })
      .catch(error => {
        throw new Error(error);
      });
  },
  async REFRESH_TOKEN({
    commit
  }, params) {
    const refreshToken = params.refreshToken;
    const loginSchema = params.loginSchema;
    console.log("Inside refresh token ", params);
    let backend = "google-oauth2";
    if (loginSchema == "fb") {
      backend = "facebook";
    }
    let res = await this.$axios
      .post(
        Config.HOST +
        `/auth/token?grant_type=refresh_token&client_id=${Config.GOOGLE_CLIENT_ID}&client_secret=${Config.GOOGLE_CLIENT_SECRET}&refresh_token=${refreshToken}&backend=${backend}`
      )
      .catch(error => {
        throw new Error(error);
      });
    console.log("Resp after refreshing token ", res);
    return res.data;
  },
  POST_GOOGLE_LOGIN({
    commit
  }, payload) {
    let googleProfile = payload.getBasicProfile();
    return UserService.post_google_login(
        payload.getAuthResponse(true).access_token
      )
      .then(({
        data
      }) => {
        console.log(data);
        data.profile_pic = googleProfile.getImageUrl();
        data.firstName = googleProfile.getGivenName();
        data.lastName = googleProfile.getFamilyName();
        console.log(data.profile_pic);
        commit("SET_GOOGLE_USER", data, payload);
      })
      .catch(error => {
        throw new Error(error);
      });
  },
  REGISTER_USER({
    commit
  }, payload) {
    return UserService.register_user(payload)
      .then(({
        data
      }) => {
        console.log("registered user");
        console.log(data);
        //TODO : When the user first registers he will not have a profile pic??
        data.profile_pic = "";
        commit("SET_USER", data, payload);
      })
      .catch(error => {
        throw new Error(error);
      });
  },
  LOGIN_USER({
    commit
  }, payload) {
    return UserService.loginUser(payload)
      .then(({
        data
      }) => {
        console.log("logging in user");
        console.log(data);

        data.profile_pic = "";
        commit("SET_USER", data, payload);
      })
      .catch(error => {
        throw new Error(error);
      });
  },
  LOGOUT_USER({
    commit
  }) {
    return UserService.logoutUser(payload)
      .then(({
        data
      }) => {
        console.log("logging in user");
        console.log(data);

        data.profile_pic = "";
        commit("RESET_USER");
      })
      .catch(error => {
        throw new Error(error);
      });
  }
};

/* eslint no-param-reassign: ["error", { "props": false }] */
export const mutations = {
  SET_USER(currentState, userData) {
    currentState.profile_pic = userData.profile_pic;
    currentState.userAccessToken = userData.user.token;
    currentState.tokenType = "Token";
    currentState.loginType = "STREET";
    currentState.bookmarks = userData.bookmarks;
    // ProfileService.headers = {
    //   Authorization: currentState.tokenType + " " + currentState.userAccessToken
    // };
    // ProfileService.getLoggedInUserProfile()
    //   .then(({ data }) => {
    //     console.log("Profile retrieval successful. ");
    //     console.log(data);
    //     currentState.profile = data;
    //   })
    //   .catch(error => {
    //     throw new Error(error);
    //   });
    console.log(currentState);
  },
  SET_NEW_GOOGLE_USER(currentState, userData) {
    console.log(userData);
    currentState.userAccessToken = userData.access_token;
    currentState.refreshToken = userData.refresh_token;
    currentState.tokenType = userData.token_type;
    currentState.loginType = "GOOGLE";
    console.log(currentState);
  },
  RESET_USER(currentState) {
    currentState.profile_pic = "";
    currentState.userAccessToken = "";
    currentState.refreshToken = "";
    currentState.tokenType = "Token";
    currentState.bookmarks = [];
    currentState.profile = undefined;
    currentState.longitude = undefined;
    currentState.latitude = undefined;
    console.log(currentState);
  },
  SET_FB_USER(currentState, userData) {
    console.log(currentState);
    currentState.profile_pic = userData.profile_pic;
    currentState.userAccessToken = userData.access_token;
    currentState.refreshToken = userData.refresh_token;
    currentState.tokenType = userData.token_type;
    currentState.loginType = "FB";
    currentState.bookmarks = userData.bookmarks;
    currentState.firstName = userData.firstName;
    currentState.lastName = userData.lastName;
    // ProfileService.headers = {
    //   Authorization: currentState.tokenType + " " + currentState.userAccessToken
    // };
    // ProfileService.getLoggedInUserProfile()
    //   .then(({ data }) => {
    //     console.log("Profile retrieval successful. ");
    //     console.log(data);
    //     currentState.profile = data;
    //   })
    //   .catch(error => {
    //     throw new Error(error);
    //   });
    console.log(currentState);
  },
  SET_GOOGLE_USER(currentState, userData) {
    console.log(currentState);
    console.log(userData);
    currentState.profile_pic = userData.profile_pic;
    currentState.userAccessToken = userData.access_token;
    currentState.refreshToken = userData.refresh_token;
    currentState.tokenType = userData.token_type;
    currentState.loginType = "GOOGLE";
    currentState.bookmarks = userData.bookmarks;
    currentState.firstName = userData.firstName;
    currentState.lastName = userData.lastName;
    // ProfileService.headers = {
    //   Authorization: currentState.tokenType + " " + currentState.userAccessToken
    // };
    // ProfileService.getLoggedInUserProfile()
    //   .then(({ data }) => {
    //     console.log("Profile retrieval successful. ");
    //     console.log(data);
    //     currentState.profile = data;
    //   })
    //   .catch(error => {
    //     throw new Error(error);
    //   });
    console.log(currentState);
  },
  updateNotificationSetting(state, value) {
    state.settings.notifications = value;
  }
};

export default {
  state,
  actions,
  mutations
};
