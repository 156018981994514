import Vue from "vue";
import { Config } from "@/config.js";
import axios from "axios";
export default {
  data() {
    return {
      postBody: "",
      headers: "",
      errors: [],
      host: "https://streetbeta-204011.appspot.com"
    };
  },
  getLoggedInUserProfile() {
    let config = {
      headers: this.headers
    };
    console.log(config);
    return axios.get(Config.HOST + "/api/profile/", config).catch(error => {
      throw new Error(error);
    });
  },
  getBookmarks() {
    let config = {
      headers: this.headers
    };
    console.log(config);
    return axios.get(Config.HOST + "/api/profile/bookmarks/", config).catch(error => {
      throw new Error(error);
    });
  }
};
