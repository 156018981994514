import store from "@/store";
import { Config } from "@/config.js";
import { RESET_USER_PROFILE } from "@/store/mutationTypes.js";
let emptyUserProfile = {
  email: "",
  firstname: null,
  lastname: null,
  bookmarks: null,
  image: null,
  occupation: null,
  is_self: false,
  is_following: false
};
export const state = {
  loggedInUserProfile: JSON.parse(JSON.stringify(emptyUserProfile)),
  currentUserProfile: JSON.parse(JSON.stringify(emptyUserProfile))
};

export const actions = {
  async FETCH_CURRENT_USER_PROFILE({ commit }, params) {
    console.log(params);
    this.$axios.setToken(
      params.userInfo.userAccessToken,
      params.userInfo.tokenType
    );
    let res = await this.$axios
      .get("/api/profiles/" + params.email)
      .catch(error => {
        throw new Error(error);
      });
    console.log("response in current user profile is ", res.data);
    commit("SET_CURRENT_USER_PROFILE", res.data);
    return res.data;
  },
  async FETCH_LOGGED_IN_USER_PROFILE({ commit }, userInfo) {
    let tokens = userInfo.userAccessToken.split(" ");
    this.$axios.setToken(tokens[1], tokens[0]);
    console.log("Calling new action", userInfo);
    let res = await this.$axios.get("/api/profile/").catch(error => {
      throw new Error(error);
    });
    console.log("response in logged in user profile is ", res.data);
    commit("SET_LOGGED_IN_USER_PROFILE", res.data);
    return res.data;
  },
  async FOLLOW_UNFOLLOW_USER({ commit }, params) {
    this.$axios.setToken(
      params.userInfo.userAccessToken,
      params.userInfo.tokenType
    );
    if (params.action === "FOLLOW") {
      console.log("Action is FOLLOW");
      let res = await this.$axios
        .post("/api/follow/" + params.email + "/")
        .catch(error => {
          throw new Error(error);
        });
      console.log("Finished following the user", res);
      commit("SET_FOLLOW_USER");
    } else {
      console.log("Action is UNFOLLOW");
      let res = await this.$axios
        .delete("/api/follow/" + params.email + "/")
        .catch(error => {
          throw new Error(error);
        });
      console.log("Finished unfollowing the user", res);
      commit("SET_UNFOLLOW_USER");
    }

    let res = await this.$axios
      .get("/api/profiles/" + params.email)
      .catch(error => {
        throw new Error(error);
      });
    console.log("response in current user profile is ", res.data);
    commit("SET_CURRENT_USER_PROFILE", res.data);
  },
  async SAVE_SUBSCRIPTION_ID({ commit }, params) {
    this.$axios.setToken(
      params.userInfo.userAccessToken,
      params.userInfo.tokenType
    );
    let res = await this.$axios
      .post("/api/profile/settings/", {
        subscription_id: params.subscriptionId
      })
      .catch(error => {
        throw new Error(error);
      });
    console.log("Saved Subscription id  ", res);
  },
  async DELETE_SUBSCRIPTION_ID({ commit }, params) {
    this.$axios.setToken(
      params.userInfo.userAccessToken,
      params.userInfo.tokenType
    );
    let res = await this.$axios
      .post("/api/profile/settings/", { subscription_id: "" })
      .catch(error => {
        throw new Error(error);
      });
    console.log("DELETED Subscription id  ", res);
  },
  async CHECK_IF_USER_EXISTS({ commit }, params) {
    this.$axios.setToken(
      params.userInfo.userAccessToken,
      params.userInfo.tokenType
    );
    let res = await this.$axios
      .get("/api/profilecheck/" + params.email)
      .catch(error => {
        throw new Error(error);
      });
    console.log("Check if user exists is complete", res);
    return res;
  },
  async UPDATE_USER_PROFILE({ commit }, params) {
    let tokens = params.userAccessToken.split(" ");
    this.$axios.setToken(tokens[1], tokens[0]);
    let res = await this.$axios
      .post("/api/profile/edit/", params.data)
      .catch(error => {
        throw new Error(error);
      });
    console.log("Updated user profile", res);
    return res;
  }
};

/* eslint no-param-reassign: ["error", { "props": false }] */
export const mutations = {
  SET_USER_PROFILE(currentState, data) {
    console.log(data);
    console.log(data.profile);
    currentState.loggedInUserName = data.profile.username;
    for (var key in data.profile) {
      currentState[key] = data.profile[key];
    }
    console.log(currentState);
  },
  SET_CURRENT_USER_PROFILE(currentState, data) {
    console.log(data);
    console.log(data.profile);
    currentState.currentUserProfile = data.profile;
  },
  SET_LOGGED_IN_USER_PROFILE(currentState, data) {
    console.log(data);
    console.log(data.profile);
    currentState.loggedInUserProfile = data.profile;
  },
  SET_FOLLOW_USER(currentState) {
    currentState.currentUserProfile.is_following = true;
  },
  SET_UNFOLLOW_USER(currentState) {
    currentState.currentUserProfile.is_following = false;
  },
  RESET_USER_PROFILE(currentState) {
    currentState.currentUserProfile.email = "";
    currentState.currentUserProfile.firstname = null;
    currentState.currentUserProfile.lastname = null;
    currentState.currentUserProfile.bookmarks = null;
    currentState.currentUserProfile.image = null;
    currentState.currentUserProfile.occupation = null;
    currentState.currentUserProfile.is_self = false;
    currentState.currentUserProfile.is_following = false;
    // currentState.currentUserProfile = JSON.parse(
    //   JSON.stringify(emptyUserProfile)
    // );
  }
};

export default {
  state,
  actions,
  mutations
};
