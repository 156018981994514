
export default {
  name: "error",
  // mounted: function(){
  //   console.log("mounted the login page");
  //   location.reload(true);
  // },
  components: {},
  mounted() {},
  data() {
    return {
      title: "STREET",
      subtitle: "Something's wrong come back later.",
      content: ""
    };
  },
  methods: {}
};
