import LocationService from "@/services/locationService";
import Location from "@/util/location";
// import {
//   FETCH_CURRENT_LOCATION,
//   FETCH_CURRENT_IP_LOCATION
// } from "@/store/actionTypes";
// import { SET_CURRENT_LOCATION } from "./mutationTypes";

export const state = () => ({
  latitude: undefined,
  longitude: undefined,
  line3: undefined,
  city: undefined,
  region: undefined,
  region_code: undefined,
  country: undefined,
  country_code: undefined,
  postal: undefined
});

export const actions = {
  FETCH_CURRENT_GPS_LOCATION({ commit }) {
    console.log("fetching current GPS location");
    var self = this;
    return new Promise(function(resolve, reject) {
      Location.getUserCompleteAddress()
        .then(response => {
          commit("SET_CURRENT_LOCATION", response.data.results[0]);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  FETCH_CURRENT_LOCATION({ commit }) {
    console.log("fetching current location.");
    var self = this;
    return new Promise(function(resolve, reject) {
      Location.getUserIPLocation()
        .then(response => {
          commit("SET_CURRENT_LOCATION", response.data.results[0]);
          Location.getUserGPSLocation()
            .then(response => {
              commit("SET_CURRENT_LOCATION", response);
              resolve();
            })
            .catch(error => {
              resolve();
            });
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  // [FETCH_CURRENT_IP_LOCATION]({ commit }) {
  //   return LocationService.getUserLatLongFromIpLookup()
  //     .then(data => {
  //       console.log(data);
  //       commit(SET_CURRENT_LOCATION, data);
  //     })
  //     .catch(error => {
  //       throw new Error(error);
  //     });
  // }
};

/* eslint no-param-reassign: ["error", { "props": false }] */
export const mutations = {
  SET_CURRENT_LOCATION(currentState, data) {
    let address_components = data.address_components;
    for (var i = 0; i < address_components.length; i++) {
      if (address_components[i].types.indexOf("street_number") != -1) {
        currentState.line1 = address_components[i].long_name;
      } else if (address_components[i].types.indexOf("route") != -1) {
        currentState.line2 = address_components[i].long_name;
      } else if (address_components[i].types.indexOf("locality") != -1) {
        currentState.line3 = address_components[i].long_name;
      } else if (
        address_components[i].types.indexOf("administrative_area_level_2") != -1
      ) {
        currentState.city = address_components[i].long_name;
      } else if (
        address_components[i].types.indexOf("administrative_area_level_1") != -1
      ) {
        currentState.region = address_components[i].long_name;
        currentState.region_code = address_components[i].short_name;
      } else if (address_components[i].types.indexOf("country") != -1) {
        currentState.country = address_components[i].long_name;
        currentState.country_code = address_components[i].short_name;
      } else if (address_components[i].types.indexOf("postal_code") != -1) {
        currentState.postal = address_components[i].long_name;
      }
    }
    currentState.latitude = data.geometry.location.lat;
    currentState.longitude = data.geometry.location.lng;
  }
};

// export default {
//   state,
//   actions,
//   mutations
// };
