import Vue from "vue";
import axios from "axios";
import { Config } from "@/config.js";
//import Location from "@/util/location.js";
export default {
  data() {
    return {
      postBody: "",
      headers: "",
      errors: [],
      host: "https://streetbeta-204011.appspot.com"
    };
  },
  getAll() {
    // console.log("calling user location when fetching articles");
    // Location.getUserCityState().then(resp => {
    //   console.log("user city and state is ", resp);
    // });
    return axios.get(Config.HOST + "/api/articles/").catch(error => {
      throw new Error(error);
    });
  },
  getArticlesByAuthor(authorId) {
    let config = {
      headers: this.headers,
      params: {
        by_author: authorId
      }
    };
    return axios.get(Config.HOST + "/api/articles/", config).catch(error => {
      throw new Error(error);
    });
  },
  getDraftsByAuthor(authorId) {
    let config = {
      headers: this.headers,
      params: {
        by_author: authorId,
        only_drafts: true
      }
    };
    return axios.get(Config.HOST + "/api/articles/", config).catch(error => {
      throw new Error(error);
    });
  },
  getAllByLocation(payload) {
    let config = {
      headers: this.headers,
      params: {
        type: payload.type,
        typeValue: payload.typeValue
      }
    };
    return axios.get(Config.HOST + "/api/articles/", config).catch(error => {
      throw new Error(error);
    });
  },
  get(articleId) {
    let config = {
      headers: this.headers
    };
    return axios
      .get(Config.HOST + "/api/articles/" + articleId + "/", config)
      .catch(error => {
        throw new Error(error);
      });
  },
  post() {
    let data = this.postBody;
    let config = {
      headers: this.headers
    };
    console.log(data);
    console.log(config);
    return axios
      .post(Config.HOST + "/api/articles/", data, config)
      .catch(error => {
        console.log("error occured in publishing an article");
        console.log(error);
        throw new Error(error);
      });
  },
  update(articleId) {
    let data = this.postBody;
    let config = {
      headers: this.headers
    };
    console.log(data);
    console.log(config);
    return axios
      .put(Config.HOST + "/api/articles/" + articleId + "/", data, config)
      .catch(error => {
        throw new Error(error);
      });
  },
  bookmark(articleId) {
    let config = {
      headers: this.headers
    };
    return axios
      .post(Config.HOST + "/api/bookmark/" + articleId + "/", undefined, config)
      .catch(error => {
        throw new Error(error);
      });
  },
  unbookmark(articleId) {
    let config = {
      headers: this.headers
    };
    return axios
      .post(
        Config.HOST + "/api/unbookmark/" + articleId + "/",
        undefined,
        config
      )
      .catch(error => {
        throw new Error(error);
      });
  },
  flag(articleId, reason) {
    let config = {
      headers: this.headers
    };
    let data = {
      reason: reason
    };
    return axios
      .post(Config.HOST + "/api/flag/" + articleId + "/", data, config)
      .catch(error => {
        throw new Error(error);
      });
  },
  getArticlesByIds(articleids) {
    let config = {
      params: {
        ids: articleids.join(",")
      }
    };
    return axios.get(Config.HOST + "/api/articles/", config).catch(error => {
      throw new Error(error);
    });
  },
  getAllSnapIds() {
    let config = {
      headers: this.headers,
      params: { only_ids: true, is_snaps: true }
    };
    return axios.get(Config.HOST + "/api/articles/", config).catch(error => {
      throw new Error(error);
    });
  },
  getAllArticleIds() {
    let config = {
      headers: this.headers,
      params: { only_ids: true }
    };
    return axios.get(Config.HOST + "/api/articles/", config).catch(error => {
      throw new Error(error);
    });
  },
  getArticleIdsByLoc(payload) {
    let config = {
      headers: this.headers,
      params: payload
    };
    return axios.get(Config.HOST + "/api/articles/", config).catch(error => {
      throw new Error(error);
    });
  },
  getSnapIdsByLoc(payload) {
    payload["is_snaps"] = true;
    let config = {
      headers: this.headers,
      params: payload
    };
    return axios.get(Config.HOST + "/api/articles/", config).catch(error => {
      throw new Error(error);
    });
  },
  getAllSnapIds() {
    let config = {
      headers: this.headers,
      params: { only_ids: true, is_snaps: true }
    };
    return axios.get(Config.HOST + "/api/articles/", config).catch(error => {
      throw new Error(error);
    });
  },
  delete(articleId) {
    let config = {
      headers: this.headers
    };
    return axios
      .delete(Config.HOST + "/api/articles/" + articleId + "/", config)
      .catch(error => {
        throw new Error(error);
      });
  },
  incrementViewCount(articleId) {
    let config = {
      headers: this.headers
    };
    return axios
      .post(
        Config.HOST + "/api/incrementviews/" + articleId + "/",
        undefined,
        config
      )
      .catch(error => {
        throw new Error(error);
      });
  }
};
