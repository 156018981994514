import Vue from "vue";
import axios from "axios";

export default {
  getUserGPSLocation: function() {
    console.log("getting user city and state from geolocation");
    return new Promise(function(resolve, reject) {
      if ("geolocation" in navigator) {
        // check if geolocation is supported/enabled on current browser
        navigator.geolocation.getCurrentPosition(
          function success(position) {
            // for when getting location is a success
            console.log(
              "latitude",
              position.coords.latitude,
              "longitude",
              position.coords.longitude
            );

            axios
              .post(
                "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
                  position.coords.latitude +
                  "," +
                  position.coords.longitude +
                  "&key=" +
                  "AIzaSyAe7WFQtEEnSMtal7_lgjIzJOEZx_IfEb4"
              )
              .then(resp => {
                let address_components =
                  resp.data.results[0].address_components;
                console.log(address_components);

                let location_data = {
                  latitude: resp.data.results[0].geometry.location.lat,
                  longitude: resp.data.results[0].geometry.location.lng,
                  address_components: resp.data.results[0].address_components
                };
                resolve(location_data);
              });
          },
          function error(error_message) {
            // for when getting location results in an error
            console.error(
              "An error has occured while retrieving location",
              error_message
            );
            reject(error_message);
          }
        );
      } else {
        // geolocation is not supported
        // get your location some other way
        console.log("geolocation is not enabled on this browser");
        reject("not supported in the browser");
      }
    });
  },
  getUserIPLocation: function() {
    return new Promise(function(resolve, reject) {
      axios
        .post(
          "https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyADEQLFyRoU82VTebDkXmxjF465RooAgyY"
        )
        .then(response => {
          console.log("response from geolocate api is ", response);
          axios
            .post(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${response.data.location.lat},${response.data.location.lng}&key=AIzaSyADEQLFyRoU82VTebDkXmxjF465RooAgyY`
            )
            .then(response => {
              console.log("response from maps api is ", response);
              resolve(response);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getUserDescLocation: function() {
    console.log("getting user city and state from geolocation");

    return new Promise(function(resolve, reject) {
      var useIp = false;
      if ("geolocation" in navigator) {
        console.log("getting user locatin using browser");
        // check if geolocation is supported/enabled on current browser
        navigator.geolocation.getCurrentPosition(
          function success(position) {
            // for when getting location is a success
            console.log(
              "latitude",
              position.coords.latitude,
              "longitude",
              position.coords.longitude
            );

            axios
              .post(
                "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
                  position.coords.latitude +
                  "," +
                  position.coords.longitude +
                  "&key=" +
                  "AIzaSyAe7WFQtEEnSMtal7_lgjIzJOEZx_IfEb4"
              )
              .then(resp => {
                let address_components =
                  resp.data.results[0].address_components;
                console.log(address_components);

                var i;
                var city = "";
                var state = "";
                for (i = 0; i < address_components.length; i++) {
                  if (address_components[i].types.indexOf("locality") != -1) {
                    city = address_components[i].long_name;
                  } else if (
                    address_components[i].types.indexOf(
                      "administrative_area_level_1"
                    ) != -1
                  ) {
                    state = address_components[i].short_name;
                  } else {
                    continue;
                  }
                }

                // let userDescAddr = city + ", " + state;
                let userDescAddr = {
                  city: city,
                  state: state
                };
                console.log(userDescAddr);
                resolve(userDescAddr);
              });
          },
          function error(error_message) {
            useIp = true;
            // for when getting location results in an error
            console.error(
              "An error has occured while retrieving location",
              error_message
            );
            console.log("geolocation is not enabled on this browser...");
            axios.post("https://ipapi.co/json").then(resp => {
              console.log("Getting user location using ip");
              console.log(resp.data.latitude);
              console.log(resp.data.longitude);
              Vue.axios
                .post(
                  "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
                    resp.data.latitude +
                    "," +
                    resp.data.longitude +
                    "&key=" +
                    "AIzaSyAe7WFQtEEnSMtal7_lgjIzJOEZx_IfEb4"
                )
                .then(resp => {
                  let address_components =
                    resp.data.results[0].address_components;
                  console.log(address_components);

                  var i;
                  var city = "";
                  var state = "";
                  for (i = 0; i < address_components.length; i++) {
                    if (address_components[i].types.indexOf("locality") != -1) {
                      city = address_components[i].long_name;
                    } else if (
                      address_components[i].types.indexOf(
                        "administrative_area_level_1"
                      ) != -1
                    ) {
                      state = address_components[i].short_name;
                    } else {
                      continue;
                    }
                  }
                  let userDescAddr = {
                    city: city
                  };
                  console.log(userDescAddr);
                  resolve(userDescAddr);
                });
            });
            // reject(error_message);
          }
        );
      } else {
        // geolocation is not supported
        // get your location some other way
        console.log("geolocation is not enabled on this browser");
        axios.post("https://ipapi.co/json").then(resp => {
          // console.log("Getting user lat")
          console.log(resp.data);
          axios
            .post(
              "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
                resp.data.lat +
                "," +
                resp.data.lon +
                "&key=" +
                "AIzaSyAe7WFQtEEnSMtal7_lgjIzJOEZx_IfEb4"
            )
            .then(resp => {
              let address_components = resp.data.results[0].address_components;
              console.log(address_components);

              var i;
              var city = "";
              var state = "";
              for (i = 0; i < address_components.length; i++) {
                if (address_components[i].types.indexOf("locality") != -1) {
                  city = address_components[i].long_name;
                } else if (
                  address_components[i].types.indexOf(
                    "administrative_area_level_1"
                  ) != -1
                ) {
                  state = address_components[i].short_name;
                } else {
                  continue;
                }
              }
              let userDescAddr = {
                city: city
              };
              console.log(userDescAddr);
              resolve(userDescAddr);
            });
        });
      }
    });
  },
  // getUserDescLocation: function () {
  //   console.log("getting user city and state from geolocation");
  //   let userDesc;
  //   let userLatLong;
  //   this.getUserLatLong().then(resp => )
  //   // this.getUserLatLongFromGeoLocation().then(resp => {
  //   //   userLatLong = resp;
  //   //   console.log("Got user lat and log from geo location");
  //   //   this.parseLatLong(userLatLong).then(resp => {
  //   //     userDesc = resp;
  //   //   });
  //   // }, fail => {
  //   //   console.log(fail);
  //   //   userLatLong = this.getUserLatLongFromIpLookup();
  //   //   console.log("Got user lat and long from ip lookup");
  //   //   this.parseLatLong(userLatLong).then(resp => {
  //   //     userDesc = resp;
  //   //   });
  //   // });

  // },
  parseLatLong: function(latLongDict) {
    return new Promise(function(resolve) {
      axios
        .post(
          "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
            latLongDict.lat +
            "," +
            latLongDict.long +
            "&key=" +
            "AIzaSyAe7WFQtEEnSMtal7_lgjIzJOEZx_IfEb4"
        )
        .then(resp => {
          let address_components = resp.data.results[0].address_components;
          console.log(address_components);

          var i;
          var city = "";
          var state = "";
          for (i = 0; i < address_components.length; i++) {
            if (address_components[i].types.indexOf("locality") != -1) {
              city = address_components[i].long_name;
            } else if (
              address_components[i].types.indexOf(
                "administrative_area_level_1"
              ) != -1
            ) {
              state = address_components[i].short_name;
            } else {
              continue;
            }
          }

          let userDescAddr = city + ", " + state;
          console.log(userDescAddr);
          resolve(userDescAddr);
        });
    });
  },
  parseAddressComponents: function(addressComponents) {
    var i;
    var city = "";
    var state = "";
    for (i = 0; i < addressComponents.length; i++) {
      if (addressComponents[i].types.indexOf("locality") != -1) {
        city = addressComponents[i].long_name;
      } else if (
        addressComponents[i].types.indexOf("administrative_area_level_1") != -1
      ) {
        state = addressComponents[i].short_name;
      } else {
        continue;
      }
    }

    let userDescAddr = city + ", " + state;
    console.log(userDescAddr);
    return userDescAddr;
  },
  getUserLatLong: function() {
    console.log("getting user lat and long from geolocation");
    return new Promise(function(resolve, reject) {
      if ("geolocation" in navigator) {
        // check if geolocation is supported/enabled on current browser
        navigator.geolocation.getCurrentPosition(
          function success(position) {
            var userLatLong = {
              lat: position.coords.latitude,
              long: position.coords.longitude
            };
            // for when getting location is a success
            console.log(userLatLong);
            resolve(userLatLong);
          },
          function error(error_message) {
            // for when getting location results in an error
            console.error(
              "An error has occured while retrieving location",
              error_message
            );
            reject(error_message);
          }
        );
      } else {
        // geolocation is not supported
        // get your location some other way
        console.log("geolocation is not enabled on this browser");
        reject("not supported in the browser");
        axios.post("https://ipapi.co/json").then(resp => {
          // console.log("Getting user lat")
          console.log(resp.data);

          var userLatLong = {
            lat: resp.data.lat,
            long: resp.data.lon
          };
          // for when getting location is a success
          console.log(userLatLong);
          resolve(userLatLong);
        });
      }
    });
  },
  getUserLatLongFromIpLookup: function() {
    console.log("getting user city and state using iplookup");
    return new Promise(function(resolve) {
      axios.post("https://ipapi.co/json").then(resp => {
        // console.log("Getting user lat")
        console.log(resp.data);

        var userLatLong = {
          lat: resp.data.lat,
          long: resp.data.lon
        };
        // for when getting location is a success
        console.log(userLatLong);
        resolve(userLatLong);
      });
    });
  }
};
