import ProfileService from "@/services/profileService";
import axios from "axios";
import store from "@/store";
import { Config } from "@/config.js";
export const state = {
  tempDataInRoute: null,
  forRoute: ""
};

export const actions = {};

/* eslint no-param-reassign: ["error", { "props": false }] */
export const mutations = {};

export default {
  state,
  actions,
  mutations
};
