export const ArticleItem = () => import('../../components/ArticleItem.vue' /* webpackChunkName: "components/article-item" */).then(c => wrapFunctional(c.default || c))
export const ArticleItemNew = () => import('../../components/ArticleItemNew.vue' /* webpackChunkName: "components/article-item-new" */).then(c => wrapFunctional(c.default || c))
export const Card = () => import('../../components/Card.vue' /* webpackChunkName: "components/card" */).then(c => wrapFunctional(c.default || c))
export const CategoriesTray = () => import('../../components/CategoriesTray.vue' /* webpackChunkName: "components/categories-tray" */).then(c => wrapFunctional(c.default || c))
export const CategoriesTrayDP = () => import('../../components/CategoriesTrayDP.vue' /* webpackChunkName: "components/categories-tray-d-p" */).then(c => wrapFunctional(c.default || c))
export const FBLogin = () => import('../../components/FBLogin.vue' /* webpackChunkName: "components/f-b-login" */).then(c => wrapFunctional(c.default || c))
export const FBLoginBackup = () => import('../../components/FBLogin_backup.vue' /* webpackChunkName: "components/f-b-login-backup" */).then(c => wrapFunctional(c.default || c))
export const FactFictionCell = () => import('../../components/FactFictionCell.vue' /* webpackChunkName: "components/fact-fiction-cell" */).then(c => wrapFunctional(c.default || c))
export const FactFictionEditor = () => import('../../components/FactFictionEditor.vue' /* webpackChunkName: "components/fact-fiction-editor" */).then(c => wrapFunctional(c.default || c))
export const FollowItem = () => import('../../components/FollowItem.vue' /* webpackChunkName: "components/follow-item" */).then(c => wrapFunctional(c.default || c))
export const GoogleLogin = () => import('../../components/GoogleLogin.vue' /* webpackChunkName: "components/google-login" */).then(c => wrapFunctional(c.default || c))
export const GoogleLoginBackup = () => import('../../components/GoogleLogin_backup.vue' /* webpackChunkName: "components/google-login-backup" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const RtEditor = () => import('../../components/RtEditor.vue' /* webpackChunkName: "components/rt-editor" */).then(c => wrapFunctional(c.default || c))
export const SFooter = () => import('../../components/SFooter.vue' /* webpackChunkName: "components/s-footer" */).then(c => wrapFunctional(c.default || c))
export const SFooterArticle = () => import('../../components/SFooterArticle.vue' /* webpackChunkName: "components/s-footer-article" */).then(c => wrapFunctional(c.default || c))
export const SHeader = () => import('../../components/SHeader.vue' /* webpackChunkName: "components/s-header" */).then(c => wrapFunctional(c.default || c))
export const SnapDialog = () => import('../../components/SnapDialog.vue' /* webpackChunkName: "components/snap-dialog" */).then(c => wrapFunctional(c.default || c))
export const SnapTray = () => import('../../components/SnapTray.vue' /* webpackChunkName: "components/snap-tray" */).then(c => wrapFunctional(c.default || c))
export const SnapTrayItem = () => import('../../components/SnapTrayItem.vue' /* webpackChunkName: "components/snap-tray-item" */).then(c => wrapFunctional(c.default || c))
export const SnapView = () => import('../../components/SnapView.vue' /* webpackChunkName: "components/snap-view" */).then(c => wrapFunctional(c.default || c))
export const SocialSharing = () => import('../../components/SocialSharing.vue' /* webpackChunkName: "components/social-sharing" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
