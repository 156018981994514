import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0de76b6b = () => interopDefault(import('../pages/Bookmarks.vue' /* webpackChunkName: "pages/Bookmarks" */))
const _a3eed218 = () => interopDefault(import('../pages/Drafts.vue' /* webpackChunkName: "pages/Drafts" */))
const _bcc37022 = () => interopDefault(import('../pages/Editor.vue' /* webpackChunkName: "pages/Editor" */))
const _c44c8514 = () => interopDefault(import('../pages/FactFiction.vue' /* webpackChunkName: "pages/FactFiction" */))
const _6fb28183 = () => interopDefault(import('../pages/Followers.vue' /* webpackChunkName: "pages/Followers" */))
const _3bcef1ff = () => interopDefault(import('../pages/Following.vue' /* webpackChunkName: "pages/Following" */))
const _3d624d75 = () => interopDefault(import('../pages/InfoView.vue' /* webpackChunkName: "pages/InfoView" */))
const _ee384452 = () => interopDefault(import('../pages/Login.vue' /* webpackChunkName: "pages/Login" */))
const _2a07a7ad = () => interopDefault(import('../pages/PasswordChange.vue' /* webpackChunkName: "pages/PasswordChange" */))
const _6a29862e = () => interopDefault(import('../pages/PasswordResetConfirm.vue' /* webpackChunkName: "pages/PasswordResetConfirm" */))
const _2f389046 = () => interopDefault(import('../pages/PasswordResetForm.vue' /* webpackChunkName: "pages/PasswordResetForm" */))
const _67cc2541 = () => interopDefault(import('../pages/Posts.vue' /* webpackChunkName: "pages/Posts" */))
const _693450b6 = () => interopDefault(import('../pages/Register.vue' /* webpackChunkName: "pages/Register" */))
const _1099b97c = () => interopDefault(import('../pages/resources.js' /* webpackChunkName: "pages/resources" */))
const _5109a525 = () => interopDefault(import('../pages/Settings.vue' /* webpackChunkName: "pages/Settings" */))
const _04968f52 = () => interopDefault(import('../pages/Snaps.vue' /* webpackChunkName: "pages/Snaps" */))
const _19d3ed27 = () => interopDefault(import('../pages/Trending.vue' /* webpackChunkName: "pages/Trending" */))
const _c9d4d166 = () => interopDefault(import('../pages/articles/_id/index.vue' /* webpackChunkName: "pages/articles/_id/index" */))
const _0c44e1e5 = () => interopDefault(import('../pages/profile/_id/index.vue' /* webpackChunkName: "pages/profile/_id/index" */))
const _47ca1ae0 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/Bookmarks",
    component: _0de76b6b,
    name: "Bookmarks"
  }, {
    path: "/Drafts",
    component: _a3eed218,
    name: "Drafts"
  }, {
    path: "/Editor",
    component: _bcc37022,
    name: "Editor"
  }, {
    path: "/FactFiction",
    component: _c44c8514,
    name: "FactFiction"
  }, {
    path: "/Followers",
    component: _6fb28183,
    name: "Followers"
  }, {
    path: "/Following",
    component: _3bcef1ff,
    name: "Following"
  }, {
    path: "/InfoView",
    component: _3d624d75,
    name: "InfoView"
  }, {
    path: "/Login",
    component: _ee384452,
    name: "Login"
  }, {
    path: "/PasswordChange",
    component: _2a07a7ad,
    name: "PasswordChange"
  }, {
    path: "/PasswordResetConfirm",
    component: _6a29862e,
    name: "PasswordResetConfirm"
  }, {
    path: "/PasswordResetForm",
    component: _2f389046,
    name: "PasswordResetForm"
  }, {
    path: "/Posts",
    component: _67cc2541,
    name: "Posts"
  }, {
    path: "/Register",
    component: _693450b6,
    name: "Register"
  }, {
    path: "/resources",
    component: _1099b97c,
    name: "resources"
  }, {
    path: "/Settings",
    component: _5109a525,
    name: "Settings"
  }, {
    path: "/Snaps",
    component: _04968f52,
    name: "Snaps"
  }, {
    path: "/Trending",
    component: _19d3ed27,
    name: "Trending"
  }, {
    path: "/articles/:id",
    component: _c9d4d166,
    name: "articles-id"
  }, {
    path: "/profile/:id",
    component: _0c44e1e5,
    name: "profile-id"
  }, {
    path: "/",
    component: _47ca1ae0,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
