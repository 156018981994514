import Vue from "vue";
import { Config } from "@/config.js";
// import axios from "axios";
// import { LOGOUT_USER } from "@/store/actionTypes.js";
import store from "@/store";
// import router from "@/router";
// Streetdbdev
// const CLIENT_ID = "z6PIiaauGOkVjh5Ns5bhwJkMob4KyCypAZw04yxD";
// const CLIENT_SECRET =
//   "QTwqB3nHeDgAtJevYWiCNu6Fu1laS8Irib3kpVBvltwcshmcXxi8pPPBjBqq31pagEyoAFx3haansD5UZM8FITZhQGZh0CXhmZSar6z1pIUfJBPBDtCyYyl0c3C8nO0m";

// Street beta
const CLIENT_ID = "FzDz46ibXWBu83EBHkvi9UCkF7C6ZAETgb2wxcKK";
const CLIENT_SECRET =
  "77OqtX7OmofOlmxS0TdW6dsFlGPlJ5TjDSPzvpAqAkVQSvhQTgz2l064EvW5w0kimR0fQ8KfbZU1NYXkkREfwQC5AZrwK87gQU2qUcOiguDY5k4yZmTf0cqQOLuIOkOK";

// Abhijith
// const CLIENT_ID=YCPzBc7sA7P0gKSOsn8mMwY5lNAwbllRoL3km6Mm
//const CLIENT_SECRET=DmxMeuHRsSKVqBd5sHPVPfJfF2gm6B3G9fhuGhndeTorQmtPnLfOaDVUuB0PomzL3HNWDBZY2CbGXg8HIi2cVfXGz1dkh2BTGlihHhITiPaWuR7UNIWxW9ivyd5Clnzm

// Dev
// const CLIENT_ID = "Zev5uDRlBGoRSOEDeTd2R1g8Qx17Gp74DM0QuAEN";
// const CLIENT_SECRET =
//   "oYlDQIHsjpeNR5KSAJ71wbOYUdYhfSrnuN3I4iNSqlQ33ALn7C68YMmdsFbDehKAVwLRsgxiwjOwIx8pDhRyG402W5rU7gXtLoRUxt9AiWkCGCcVpAwErhNQe5J4bb1j";

export default {
  data() {
    return {
      host: "https://streetbeta-204011.appspot.com"
    };
  },
  post(user_token) {
    return axios
      .post(
        Config.HOST +
          `/auth/convert-token?grant_type=convert_token&client_id=${Config.CLIENT_ID}&client_secret=${Config.CLIENT_SECRET}&token=` +
          user_token +
          "&backend=facebook"
      )
      .catch(error => {
        throw new Error(error);
      });
  },
  register_user(user_details) {
    return this.$axios
      .post("/api/dj-rest-auth/register/", user_details)
      .catch(error => {
        throw new Error(error.response);
      });
  },
  loginUser(user_details) {
    return axios
      .post(Config.HOST + "/api/users/login", user_details)
      .catch(error => {
        throw new Error(error);
      });
  },
  logoutUser(user_details) {
    return axios.post(Config.HOST + "/api/users/logout").catch(error => {
      throw new Error(error);
    });
  },
  resetPassword(emailid) {
    var data = {
      emailid: emailid
    };
    return axios.post(Config.HOST + "/api/resetpassword", data).catch(error => {
      throw new Error(error);
    });
  },
  changeUserPassword(token, newPassword, emailid) {
    var data = {
      token: token,
      newpassword: newPassword,
      emailid: emailid
    };
    return axios
      .post(Config.HOST + "/api/changepassword", data)
      .catch(error => {
        throw new Error(error);
      });
  },
  post_google_login(user_token) {
    return axios
      .post(
        Config.HOST +
          `/auth/convert-token?grant_type=convert_token&client_id=${Config.GOOGLE_CLIENT_ID}&client_secret=${Config.GOOGLE_CLIENT_SECRET}&token=` +
          user_token +
          "&backend=google-oauth2"
      )
      .catch(error => {
        throw new Error(error);
      });
  },
  userLogout: function(loginType) {
    console.log("logging out user");
    // console.log(store.state.user.loginType);
    return new Promise(function(resolve, reject) {
      try {
        if (loginType === "GOOGLE") {
          var auth2 = gapi.auth2.getAuthInstance();
          console.log(auth2);
          auth2.signOut().then(function() {
            auth2.disconnect();
          });
          console.log("Done signing out of user");
        } else if (loginType === "FB") {
          FB.logout(function(response) {
            console.log("Logging out fb user");
          });
        }
        resolve();
      } catch (err) {
        // This is a temporary solution to not throw error gapi is undefined. Fix it ASAP
        console.log(
          "Error occured in logging out user so taking him back to home"
        );
        reject;
      }
    });

    // store.dispatch(LOGOUT_USER).then(response => {
    //   console.log(response);
    //   // router.push({ path: "/" });
    // });
  }
};
