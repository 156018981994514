import ProfileService from "@/services/profileService";
import axios from "axios";
import store from "@/store";
import { Config } from "@/config.js";
export const state = {
  email: "",
  firstname: null,
  lastname: null,
  bookmarks: null,
  image: null,
  occupation: null,
  followers: [],
  following: [],
  bookmarks: [],
  is_following: false,
  bio: "",
  is_self: false
};

export const actions = {
  FETCH_USER_PROFILE({ commit, state }, payload) {
    ProfileService.headers = {
      Authorization: payload.token
    };
    console.log(ProfileService.headers);
    return ProfileService.get()
      .then(({ data }) => {
        console.log(data);
        commit("SET_USER_PROFILE", data);
      })
      .catch(error => {
        throw new Error(error);
      });
  }
};

/* eslint no-param-reassign: ["error", { "props": false }] */
export const mutations = {
  SET_USER_PROFILE(currentState, data) {
    currentState.email = data.profile.email;
    currentState.firstname = data.profile.firstname;
    currentState.lastname = data.profile.lastname;
    currentState.bookmarks = data.profile.bookmarks;
    currentState.image = data.profile.image;
    currentState.occupation = data.profile.occupation;
    currentState.followers = data.profile.followers;
    currentState.following = data.profile.following;
    currentState.bookmarks = data.profile.bookmarks;
    currentState.is_following = data.profile.is_following;
    currentState.bio = data.profile.bio;
    currentState.is_self = data.profile.is_self;
  }
};

export default {
  state,
  actions,
  mutations
};
