import LocationService from "@/services/locationService";
export const state = {
  article: [],
  trendingLocations: []
};

export const actions = {

  async FETCH_ARTICLE({ commit }, params) {
    console.log(
      "Inside article store to fetch article...",
      // params.articleId,
      params.userAccessToken
    );
    if (!this.$auth.loggedIn) {
      console.log(this.$axios.defaults.headers);
      console.log(this.$auth.user);
    }
    let reqParams = {}
    if ('slug' in params) {
      reqParams = { slug: params.slug }
    } else {
      reqParams = { ids: params.articleId }
    }
    let res = await this.$axios
      .get("/api/articles/", { params: reqParams })
      .catch(error => {
        throw new Error(error);
      });
    console.log("response in fetching article ", res.data);
    if (res.data.length > 0) {
      if (res.data[0].author.is_publisher) {
        const instance = this.$axios.create();
        instance.defaults.baseUrl = "";
        delete instance.defaults.headers.common["Authorization"];
        let res2 = await instance
          .get(res.data[0].text)
          .catch((error) => {
            throw new Error(error);
          });
        res.data[0].text = res2.data;
      }
      commit("SET_ARTICLE", res.data[0]);
      return res.data[0];
    }
  },

  FETCH_TRENDING_LOCATIONS({ commit }) {
    return LocationService.getTrendingLocations()
      .then(({ data }) => {
        commit("SET_TRENDING_LOCATIONS", data);
      })
      .catch(error => {
        throw new Error(error);
      });
  },
  async TOGGLE_BOOKMARK({ commit }, params) {
    console.log("action is ", params.action);
    console.log(params.userInfo.userAccessToken, params.userInfo.tokenType);
    let tokens = params.userAccessToken.split(" ");
    this.$axios.setToken(tokens[1], tokens[0]);
    if (params.action === "BOOKMARK") {
      let res = await this.$axios
        .post("/api/bookmark/" + params.articleId + "/")
        .catch(error => {
          throw new Error(error);
        });
      console.log("response in article bookmark is ", res.data);
      commit("SET_ARTICLE_BOOKMARK", res.data);
    } else {
      let res = await this.$axios
        .post("/api/unbookmark/" + params.articleId + "/")
        .catch(error => {
          throw new Error(error);
        });
      console.log("response in article unbookmark is ", res.data);
      commit("SET_ARTICLE_UNBOOKMARK", res.data);
    }
  }
};

/* eslint no-param-reassign: ["error", { "props": false }] */
export const mutations = {
  SET_ARTICLE(currentState, data) {
    currentState.article = data;
  },
  SET_TRENDING_LOCATIONS(currentState, data) {
    currentState.trendingLocations = data;
  },
  SET_ARTICLE_BOOKMARK(currentState, data) {
    currentState.article.is_bookmarked = true;
  },
  SET_ARTICLE_UNBOOKMARK(currentState, data) {
    currentState.article.is_bookmarked = false;
  }
};

// export default {
//   state,
//   actions,
//   mutations
// };
