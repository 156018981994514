let env = "prod";
let HOST = "";
let BASE_URL = "";
let FB_APP_ID = "";
let CLIENT_ID = "Ieiw2gySqJRGduolelWqVaYBELdoLVYiuaHm0G8W";
let CLIENT_SECRET =
  "9lgxtfh1CVeXAWyv9HCpZyAjf8WUrbWgPd6j6xHURaEsyjgmR18z5TVh3bSpfmR3C15RgtwhqOq7lPZv0NJ1tmCTo30LzlHrxEmY56HBpnfXloERjJe8kukKcBiFMIEW";
let GOOGLE_CLIENT_ID = "Ieiw2gySqJRGduolelWqVaYBELdoLVYiuaHm0G8W";
let GOOGLE_CLIENT_SECRET =
  "9lgxtfh1CVeXAWyv9HCpZyAjf8WUrbWgPd6j6xHURaEsyjgmR18z5TVh3bSpfmR3C15RgtwhqOq7lPZv0NJ1tmCTo30LzlHrxEmY56HBpnfXloERjJe8kukKcBiFMIEW";
if (env == "prod") {
  HOST = "https://api.streetnews.live";
  BASE_URL = "https://streetnews.live";
  FB_APP_ID = "566364420645277";
  GOOGLE_CLIENT_ID =
    "896727045966-3g2q2u4ee0i722hioiustva014crmjdo.apps.googleusercontent.com";
} else if (env == "beta") {
  HOST = "https://still-totality-258323.appspot.com";
  FB_APP_ID = "583032305558892";
  GOOGLE_CLIENT_ID = "Ieiw2gySqJRGduolelWqVaYBELdoLVYiuaHm0G8W";
} else if (env == "dev") {
  HOST = "https://still-totality-258323.appspot.com";
  GOOGLE_CLIENT_ID = "Ieiw2gySqJRGduolelWqVaYBELdoLVYiuaHm0G8W";
  FB_APP_ID = "373185833252905";
} else if (env == "network") {
  FB_APP_ID = "566364420645277";
  // FB_APP_ID = "805538826484741";
  HOST = "http://127.0.0.1:8000";
  BASE_URL = "http://localhost:3000";
  GOOGLE_CLIENT_ID =
    "896727045966-3g2q2u4ee0i722hioiustva014crmjdo.apps.googleusercontent.com";
} else {
  FB_APP_ID = "373185833252905";
  HOST = "https://still-totality-258323.appspot.com";
  GOOGLE_CLIENT_ID = "Ieiw2gySqJRGduolelWqVaYBELdoLVYiuaHm0G8W";
}

export const Config = {
  HOST: HOST,
  BASE_URL: BASE_URL,
  FB_APP_ID: FB_APP_ID,
  CLIENT_ID: CLIENT_ID,
  CLIENT_SECRET: CLIENT_SECRET,
  GOOGLE_CLIENT_ID: GOOGLE_CLIENT_ID,
  GOOGLE_CLIENT_SECRET: GOOGLE_CLIENT_SECRET
};
